/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c9c9c9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

::-webkit-input-placeholder {
  font-style: italic;
  font-size: 16px;
}

body {
  /* font-family: "Gill Sans", sans-serif; */
  font-family: system-ui;
}

.swal2-custom-height .swal2-popup {
  height: auto !important;
  max-height: 50vh;
  /* Adjust the maximum height as needed */
}
/* Default styles for the modal */
.swal2-responsive-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swal2-responsive-popup {
  width: 80%; /* Adjust width for medium screens */
  max-width: 500px; /* Set a max width */
}

/* Media query for small screens (e.g., phones) */
@media (max-width: 600px) {
  .swal2-responsive-popup {
    width: 95%; /* Full width for smaller screens */
    max-width: 95%; /* Set max width for small screens */
  }

  .swal2-responsive-title {
    font-size: 18px; /* Adjust font size for small screens */
  }

  .swal2-responsive-content {
    font-size: 16px; /* Adjust font size for small screens */
  }

  .swal2-responsive-actions {
    flex-direction: column;
    align-items: center;
  }
}
